import {
  Icon,
  IconButton,
  MoreActionsDropdown,
  MultiUserPreview,
  Thumbnail,
  UserPreview,
} from "@components/library";
import type { ExtendedDropdownOption } from "@components/library/Dropdowns/DropdownOption";
import { COLORS, FONTS, STYLES } from "@constants";
import {
  clearMessengerState,
  setHasUnreadMessage,
  setIsMessengerExpanded,
  setIsMessengerMinimized,
  setIsMessengerOpen,
} from "@redux/actions/messengerActions";
import { MessageRecipient } from "@tsTypes/index";
import { Dispatch, SetStateAction } from "react";
import { batch, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import styled, { css } from "styled-components";

interface Props {
  recipient: MessageRecipient;
  messengerThread: Record<string, any>;
  proposalTitle?: string | null;
  isMessengerMinimized: boolean;
  isMessengerExpanded: boolean;
  hasUnreadMessage: boolean;
  setIsParticipantsModalOpen: Dispatch<SetStateAction<boolean>>;
}

const MessengerHeader = ({
  recipient,
  proposalTitle,
  isMessengerMinimized,
  messengerThread,
  isMessengerExpanded,
  hasUnreadMessage,
  setIsParticipantsModalOpen,
}: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const toggleMinimizeMessenger = () => {
    // if user opens messenger mark as read
    if (isMessengerMinimized) dispatch(setHasUnreadMessage(false));
    // reduce size of messenger window if minimized
    if (isMessengerExpanded) dispatch(setIsMessengerExpanded(false));
    dispatch(setIsMessengerMinimized(!isMessengerMinimized));
  };

  const handleCloseMessenger = (e: Event) => {
    e.stopPropagation();
    batch(() => {
      dispatch(setIsMessengerOpen(false));
      dispatch(clearMessengerState());
    });
  };

  const noThreadMoreActionsOptions: ExtendedDropdownOption[] = [
    {
      // expand if reduced and reduce if expanded
      value: "EXPAND_OR_REDUCE",
      label: isMessengerExpanded ? "Reduce" : "Expand",
      onSelect: () => dispatch(setIsMessengerExpanded(!isMessengerExpanded)),
      iconName: isMessengerExpanded ? "Reduce" : "Expand",
    },
    {
      value: "VIEW_PROFILE",
      label: `Visit ${recipient.name}'s profile`,
      onSelect: () => history.push(`/profile/${recipient.profile_id}`),
      iconName: "Profile",
    },
  ];
  const moreActionsOptions: ExtendedDropdownOption[] = [
    ...noThreadMoreActionsOptions,
    {
      value: "OPEN_IN_INBOX",
      label: "Open in Inbox",
      onSelect: () => history.push(`/inbox?thread_id=${messengerThread?.id}`),
      iconName: "Open in a new tab",
    },
    {
      value: "WHO_CAN_SEE_THIS_CONVERSATION",
      label: `Who can see this conversation`,
      onSelect: () => setIsParticipantsModalOpen(true),
      iconName: "Employees",
    },
  ];

  let participants = messengerThread?.id
    ? [messengerThread.scientist_user, ...(messengerThread.company_members ?? [])]
    : [recipient];
  if (!messengerThread?.proposal) participants = messengerThread?.recipients || [recipient];

  const IS_SINGLE_PARTICIPANT = participants.length <= 2;

  const userPreview = IS_SINGLE_PARTICIPANT ? (
    <UserPreview size="sm" user={recipient} />
  ) : (
    <MultiUserPreview users={participants} />
  );

  return (
    <HeaderContainer
      isMessengerMinimized={isMessengerMinimized}
      onClick={isMessengerMinimized ? toggleMinimizeMessenger : undefined}
    >
      {isMessengerMinimized ? (
        <FlexContainer>
          <Thumbnail size="sm" users={participants} single={IS_SINGLE_PARTICIPANT} />
          {IS_SINGLE_PARTICIPANT && (
            <Name hasUnreadMessage={hasUnreadMessage}>{recipient.name}</Name>
          )}
          {hasUnreadMessage && <UnreadDot />}
        </FlexContainer>
      ) : (
        <div>
          {userPreview}
          {proposalTitle && (
            <ProposalTitleContainer isMulti={!IS_SINGLE_PARTICIPANT}>
              <Icon name="Proposal" size="sm" margin="0 4px 0 0" color={COLORS.NEUTRAL_500} />
              <ProposalTitle>{proposalTitle}</ProposalTitle>
            </ProposalTitleContainer>
          )}
        </div>
      )}
      <IconContainer>
        {!isMessengerMinimized && (
          <>
            <MoreActionsDropdown
              options={messengerThread ? moreActionsOptions : noThreadMoreActionsOptions}
              menuHorizontalPosition="right"
            >
              <IconButton size="xs" variant="fourth" iconName="More Actions" tooltipWidth="100px" />
            </MoreActionsDropdown>
            <IconButton
              iconName="Minimize"
              size="xs"
              variant="fourth"
              onClick={toggleMinimizeMessenger}
            />
          </>
        )}
        <IconButton
          iconName="Close"
          size="xs"
          variant="fourth"
          onClick={handleCloseMessenger}
          data-testid="close-button"
        />
      </IconContainer>
    </HeaderContainer>
  );
};

export default MessengerHeader;

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: ${({ isMessengerMinimized }) => (isMessengerMinimized ? "8px" : "12px 8px 12px 12px")};
  border: 0px solid ${COLORS.NEUTRAL_200};
  box-shadow: 0px 4px 3px -2px rgba(0, 115, 200, 0.1);
  border-radius: 12px 12px 0px 0px;
  ${({ isMessengerMinimized }) =>
    isMessengerMinimized &&
    css`
      &:hover {
        background: ${COLORS.NEUTRAL_50};
        cursor: pointer;
      }
    `}
`;

const IconContainer = styled.div`
  display: flex;
  height: 24px;
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Name = styled.div`
  width: max-content;
  max-width: ${({ hasUnreadMessage }) => (hasUnreadMessage ? "93px" : "115px")};
  margin-left: 8px;
  color: ${COLORS.BLACK};
  ${FONTS.TAG_SEMIBOLD_1};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const UnreadDot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${COLORS.RED};
  margin-left: 8px;
`;
const ProposalTitleContainer = styled.div`
  display: flex;
  margin-left: ${({ isMulti }) => (isMulti ? "60" : "44")}px;
  margin-top: 8px;
`;
const ProposalTitle = styled.div`
  color: ${COLORS.NEUTRAL_500};
  ${FONTS.MEDIUM_3};
  ${STYLES.ONE_LINE}
`;
