import { RootState } from "@redux/store";
import { getUser } from "@requests/users";
import { emptyActionGenerator, payloadActionGenerator } from "@utils/redux";
import { authenticityToken } from "@utils/requests";
import axios from "axios";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";

export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const LOAD_CURRENT_USER = "LOAD_CURRENT_USER";
export const SET_PATENTS = "SET_PATENTS";
export const LOAD_PATENTS = "LOAD_PATENTS";
export const SET_PUBLICATIONS = "SET_PUBLICATIONS";
export const LOAD_PUBLICATIONS = "LOAD_PUBLICATIONS";
export const SET_FUNDINGS = "SET_FUNDINGS";
export const LOAD_FUNDINGS = "LOAD_FUNDINGS";
export const TOGGLE_LOGIN_MODAL = "TOGGLE_LOGIN_MODAL";
export const START_DELETE_PROPOSAL = "START_DELETE_PROPOSAL";
export const FINISH_DELETE_PROPOSAL = "FINISH_DELETE_PROPOSAL";
export const LOADING_RESEARCH_INTEREST_FOLLOWS = "LOADING_RESEARCH_INTEREST_FOLLOWS";
export const UPDATE_RESEARCH_INTEREST_FOLLOWS = "UPDATE_RESEARCH_INTEREST_FOLLOWS";
export const SET_UNREAD_COUNT = "SET_UNREAD_COUNT";
export const SET_PROPOSAL_EVAL = "SET_PROPOSAL_EVAL";
export const UPDATE_PROFILE_IMAGE = "UPDATE_PROFILE_IMAGE";
export const FINISH_FOLLOW_UNFOLLOW_COMPANY = "FINISH_FOLLOW_UNFOLLOW_COMPANY";

export const setCurrentUser = payloadActionGenerator(SET_CURRENT_USER);
export const loadCurrentUser = emptyActionGenerator(LOAD_CURRENT_USER);
export const setPatents = payloadActionGenerator(SET_PATENTS);
export const loadPatents = emptyActionGenerator(LOAD_PATENTS);
export const setPublications = payloadActionGenerator(SET_PUBLICATIONS);
export const loadPublications = emptyActionGenerator(LOAD_PUBLICATIONS);
export const setFundings = payloadActionGenerator(SET_FUNDINGS);
export const loadFundings = emptyActionGenerator(LOAD_FUNDINGS);
export const toggleLoginModal = payloadActionGenerator(TOGGLE_LOGIN_MODAL);
export const startDeleteProposal = payloadActionGenerator(START_DELETE_PROPOSAL);
export const finishDeleteProposal = payloadActionGenerator(FINISH_DELETE_PROPOSAL);
export const loadingResearchInterestFollows = payloadActionGenerator(
  LOADING_RESEARCH_INTEREST_FOLLOWS
);
export const updateResearchInterestFollows = payloadActionGenerator(
  UPDATE_RESEARCH_INTEREST_FOLLOWS
);
export const setUnreadCount = payloadActionGenerator(SET_UNREAD_COUNT);
export const setProposalEval = payloadActionGenerator(SET_PROPOSAL_EVAL);
export const updateProfileImage = payloadActionGenerator(UPDATE_PROFILE_IMAGE);
export const finishFollowUnfollowCompany = payloadActionGenerator(FINISH_FOLLOW_UNFOLLOW_COMPANY);

export const fetchCurrentUser = (fetchFromSession = false) => {
  return async (dispatch: ThunkDispatch<RootState, any, AnyAction>) => {
    dispatch(loadCurrentUser());

    if (!gon.current_user_id) {
      dispatch(setCurrentUser({ profile_info: {} }));
      return { profile_info: {} };
    }

    const data = await getUser(gon.current_user_id, fetchFromSession);
    const user = data?.user;

    if (!user) {
      dispatch(setCurrentUser({ profile_info: {} }));
      return { profile_info: {} };
    }

    dispatch(setCurrentUser(user));
    return user;
  };
};

export const fetchPatents = (profileInfoId: number) => {
  return async (dispatch) => {
    dispatch(loadPatents());

    const responseJson = await axios.get(`/patents?profile_info_id=${profileInfoId}`, {
      headers: {
        Accept: "application/json",
      },
    });

    dispatch(setPatents(responseJson.data.patents));

    return responseJson.data.patents;
  };
};

export const fetchPublications = (profileInfoId: number) => {
  return async (dispatch) => {
    dispatch(loadPublications());

    const responseJson = await axios.get(`/publications?profile_info_id=${profileInfoId}`, {
      headers: {
        Accept: "application/json",
      },
    });

    dispatch(setPublications(responseJson.data.publications));

    return responseJson.data.publications;
  };
};

export const fetchFundings = (profileInfoId: number) => {
  return async (dispatch) => {
    dispatch(loadFundings());

    const responseJson = await axios.get(`/fundings?profile_info_id=${profileInfoId}`, {
      headers: {
        Accept: "application/json",
      },
    });

    dispatch(setFundings(responseJson.data.fundings));

    return responseJson.data.fundings;
  };
};

export const deleteProposal = (proposalId) => {
  return async (dispatch) => {
    dispatch(startDeleteProposal());
    const url = `/proposals/${proposalId}`;

    try {
      await axios.delete(url, {
        headers: {
          "X-CSRF-Token": authenticityToken() as any,
          Accept: "application/json",
        },
      });

      dispatch(finishDeleteProposal({ proposalId }));
    } catch (err) {
      dispatch(finishDeleteProposal());
    }
  };
};

export const followResearchInterest = (profile_info_id, research_interest_id) => {
  return async (dispatch) => {
    dispatch(loadingResearchInterestFollows(true));

    const data = {
      profile_info_id,
      research_interest_id,
    };

    try {
      const responseJson = await axios.post("/research_interest_follows/", data, {
        headers: {
          Accept: "application/json",
          "X-CSRF-Token": authenticityToken() as any,
        },
      });
      dispatch(updateResearchInterestFollows(responseJson.data.research_interest_follows));
    } catch (err) {
      dispatch(loadingResearchInterestFollows(false));
    }
  };
};

export const unfollowResearchInterest = (id) => {
  return async (dispatch) => {
    dispatch(loadingResearchInterestFollows(true));

    try {
      const responseJson = await axios.delete(`/research_interest_follows/${id}`, {
        headers: {
          Accept: "application/json",
          "X-CSRF-Token": authenticityToken() as any,
        },
      });
      dispatch(updateResearchInterestFollows(responseJson.data.research_interest_follows));
    } catch (err) {
      dispatch(loadingResearchInterestFollows(false));
    }
  };
};

export const checkUnread = () => {
  return async (dispatch) => {
    const responseJson = await axios.get(`/check_unread`);
    dispatch(setUnreadCount(responseJson.data.unread_count));
  };
};

export const fetchProposalEval = (sponsorId, proposalId) => {
  return async (dispatch) => {
    try {
      const responseJson = await axios.get("/proposal_evals", {
        params: {
          proposal_id: proposalId,
          sponsor_profile_id: sponsorId,
        },
        headers: {
          Accept: "application/json",
          "X-CSRF-Token": authenticityToken() as any,
        },
      });

      dispatch(setProposalEval(responseJson.data.proposal_eval));
    } catch (err) {
      console.log(err);
    }
  };
};

export const updateProposalEval = (proposalId, updatedEval) => {
  return async (dispatch) => {
    try {
      const responseJson = await axios.put(`/proposal_evals/${proposalId}`, updatedEval, {
        headers: {
          Accept: "application/json",
          "X-CSRF-Token": authenticityToken() as any,
        },
      });

      dispatch(setProposalEval(responseJson.data.proposal_eval));
    } catch (err) {
      console.log(err);
    }
  };
};
