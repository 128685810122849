export enum ProposalStatus {
  OPEN = 0,
  QUALIFIED = 3,
  SHORTLISTED = 4,
  FINALIST = 5,
  FUNDED = 6,
  DECLINED = 2,
}

// Stage is based on status but has more info such as furthest status reached, etc.
// Used exclusively for the front-end, such as in ProposalStageTag
export enum ProposalStageNotStatus {
  DRAFT = 0,
  SUBMITTED = 1,
  QUALIFIED = 2,
  SHORTLISTED = 3,
  FINALIST = 4,
  DECLINED = 5,
  DECLINED_QUALIFIED = 6,
  DECLINED_SHORTLISTED = 7,
  DECLINED_FINALIST = 8,
}

export const TECHNOLOGY_READINESS_LEVELS = {
  1: "Basic principles observed",
  2: "Concept development",
  3: "Experimental proof of concept",
  4: "Validated in lab conditions",
  5: "Validated in relevant environment",
  6: "Demonstrated in relevant environment",
  7: "Regulatory approval",
  8: "Product in production",
  9: "Product in market",
};

export interface CompanyProposalLibraryFilterOptions {
  rfpIdFilter?: string[];
  requestIdFilter?: string[];
  statusFilter?: string[];
  countryFilter?: string[];
  keywordFilter?: string[];
  institutionTypeFilter?: string[];
  institutionNameFilter?: string[];
  technologyReadinessLevelFilter?: string[];
  searchFilter?: string[];
  searchDirection?: "ASC" | "DESC";
  pageNumber?: number;
}
