import NavBar from "@components/Navbar";
import { Button } from "@components/library";
import { COLORS, FONTS } from "@constants";
import backgroundImage from "@images/backgrounds/inbox-background.svg";
import haloLogo from "@images/logos/halo_logo.svg";
import { setShowErrorBoundary } from "@redux/actions/defaultActions";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

const ErrorBoundaryFallback = ({ error }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setShowErrorBoundary(true));
  }, []);

  // NOTE: (justinpchang) If ChunkLoadError, attempt to refresh the page once to refresh JS cache.
  if (error.name === "ChunkLoadError") {
    if (!window.location.hash.endsWith("refresh")) {
      window.location.replace(window.location + "#refresh");
      window.location.reload();
    }
  }

  return (
    <Container>
      {gon?.current_user_id ? <NavBar /> : <Logo src={haloLogo} />}
      <Title>Oh dear! Something went wrong</Title>
      <Quote>
        <b>&quot;I was taught that the way of progress was neither swift nor easy.&quot;</b>
        <p>- Marie Curie</p>
      </Quote>
      <a href="/redirect_login">
        <Button type="button">Go to Homepage</Button>
      </a>
      <ContactLink>
        Having trouble? &nbsp;
        <a href="/contact">Contact us</a>
      </ContactLink>
      <Background img={backgroundImage} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${COLORS.BLACK};
`;

const Logo = styled.img`
  position: absolute;
  margin: 60px auto 0;
`;

const Title = styled.div`
  ${FONTS.HEADING_2_SEMIBOLD};
  margin-bottom: 30px;
  margin-top: 150px;
`;

const Quote = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  ${FONTS.REGULAR_1};

  p {
    margin-top: 10px;
  }
`;

const ContactLink = styled.div`
  ${FONTS.REGULAR_2};
  margin: 24px 0 4px;

  a {
    text-decoration: underline;
    color: ${COLORS.BLACK};
    &:hover {
      color: ${COLORS.HALO_BLUE};
    }
  }
`;

const Background = styled.div`
  width: 100%;
  height: 660px;
  background-image: url(${({ img }) => img});
  background-size: contain;
`;

export default ErrorBoundaryFallback;
