import { getMostRecentSubscription } from "@requests/subscriptions";
import { getUserFollows } from "@requests/users";
import { payloadActionGenerator } from "@utils/redux";
import {
  batchFetchByRequest,
  fetchProposalMaterials,
  markProposalAsSeen,
} from "src/requests/proposals";
import { getCompanyMembers } from "src/requests/sponsors";

export const LOAD_SUBSCRIPTION = "LOAD_SUBSCRIPTION";
export const SET_SUBSCRIPTION = "SET_SUBSCRIPTION";
export const LOAD_PROPOSALS = "LOAD_PROPOSALS";
export const SET_PROPOSALS = "SET_PROPOSALS";
export const SET_PROPOSAL_MATERIALS = "SET_PROPOSAL_MATERIALS";
export const SET_REVIEWER_DASHBOARD_RFP = "SET_REVIEWER_DASHBOARD_RFP";
export const SET_PROPOSAL_DASHBOARD_RFP = "SET_PROPOSAL_DASHBOARD_RFP";
export const LOAD_USER_FOLLOW = "LOAD_USER_FOLLOW";
export const SET_USER_FOLLOW = "SET_USER_FOLLOW";
export const LOAD_COMPANY_MEMBERS = "LOAD_COMPANY_MEMBERS";
export const SET_COMPANY_MEMBERS = "SET_COMPANY_MEMBERS";
export const LOADING_MESSAGES = "LOADING_MESSAGES";
export const SET_MESSAGES = "SET_MESSAGES";
export const SET_VIEW_AS_COMPANY_SUPER_ADMIN = "SET_VIEW_AS_COMPANY_SUPER_ADMIN";
export const SET_PROPOSAL_AS_SEEN = "SET_PROPOSAL_AS_SEEN";

export const loadSubscription = payloadActionGenerator(LOAD_SUBSCRIPTION);
export const setSubscription = payloadActionGenerator(SET_SUBSCRIPTION);
export const loadProposals = payloadActionGenerator(LOAD_PROPOSALS);
export const setProposals = payloadActionGenerator(SET_PROPOSALS);
export const setProposalMaterials = payloadActionGenerator(SET_PROPOSAL_MATERIALS);
export const setProposalDashboardRequest = payloadActionGenerator(SET_PROPOSAL_DASHBOARD_RFP);
export const loadUserFollow = payloadActionGenerator(LOAD_USER_FOLLOW);
export const setUserFollow = payloadActionGenerator(SET_USER_FOLLOW);
export const loadCompanyMembers = payloadActionGenerator(LOAD_COMPANY_MEMBERS);
export const setCompanyMembers = payloadActionGenerator(SET_COMPANY_MEMBERS);
export const loadingMessages = payloadActionGenerator(LOADING_MESSAGES);
export const setMessages = payloadActionGenerator(SET_MESSAGES);
export const setViewAsCompanySuperAdmin = payloadActionGenerator(SET_VIEW_AS_COMPANY_SUPER_ADMIN);
export const setProposalAsSeen = payloadActionGenerator(SET_PROPOSAL_AS_SEEN);

export const fetchSubscription = (companyIdentifier) => {
  return async (dispatch) => {
    const subscription = await getMostRecentSubscription(companyIdentifier);

    dispatch(setSubscription(subscription));

    return subscription;
  };
};

export const fetchUserFollow = (userId) => {
  return async (dispatch) => {
    dispatch(loadUserFollow());
    const { user_follow } = await getUserFollows(userId);
    dispatch(setUserFollow(user_follow && user_follow.length > 0));
  };
};

export const batchFetchProposals = ({
  requestId,
  shouldClear = false,
}: {
  requestId: number;
  shouldClear?: boolean;
}) => {
  return async (dispatch) => {
    dispatch(loadProposals());

    if (shouldClear) {
      dispatch(setProposals([]));
      return [];
    }
    const proposals = await batchFetchByRequest(requestId);

    dispatch(setProposals(proposals));

    return proposals;
  };
};

export const getProposalMaterials = (slug) => {
  return async (dispatch) => {
    const materials = await fetchProposalMaterials(slug);

    dispatch(setProposalMaterials({ slug, materials }));

    return materials;
  };
};

export const fetchAllCompanyMembers = (companyId) => {
  return async (dispatch) => {
    dispatch(loadCompanyMembers());

    const { sponsor_users } = await getCompanyMembers(companyId);
    dispatch(setCompanyMembers(sponsor_users));

    return sponsor_users;
  };
};

export const updateProposalAsSeen = (proposalId, currentUser) => {
  return async (dispatch) => {
    await markProposalAsSeen(proposalId);
    dispatch(setProposalAsSeen({ proposalId, currentUser }));
  };
};
