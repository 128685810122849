import { ChangeEvent, ReactNode, useEffect, useRef, useState } from "react";
import { flushSync } from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import styled from "styled-components";

import {
  Button,
  ErrorModal,
  Icon,
  IconButton,
  InfoBanner,
  MessageAttachment,
  Tooltip,
} from "@components/library";
import { ABLY_CHANNELS, COLORS, FONTS, STYLES } from "@constants";
import { setMessengerThread } from "@redux/actions/messengerActions";
import { RootState } from "@redux/store";
import {
  MessageAttachmentAsJson,
  addMessageToThread,
  createMessageThread,
  getDraftMessage,
  setDraftMessage,
  uploadAttachment,
} from "@requests/messages";
import { MessageAttachmentContentType } from "@tsTypes/messages";
import ably from "@utils/ably";
import { debounce } from "@utils/appUtils";

import { UserRole } from "@tsTypes/users";
import { USER_ROLES } from "src/constants/users";
import AttachmentsInfoModal from "./AttachmentsInfoModal";

const MAX_FILE_SIZE_IN_BYTES = 5_000_000; // 5MB
const MESSAGE_INPUT_MIN_HEIGHT = 112;

const MessageCompose = ({
  currentThread,
  setMessages,
  isInbox = false,
  recipient,
  openGallery,
}) => {
  const [messageInput, setMessageInput] = useState("");
  const [isFetchingDraft, setIsFetchingDraft] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [attachments, setAttachments] = useState<Partial<MessageAttachmentAsJson>[]>([]);
  const [isAttachmentsInfoModalOpen, setIsAttachmentsInfoModalOpen] = useState(false);
  const [isAttachmentUploading, setIsAttachmentUploading] = useState(false);
  const [uploadPercentage, setUploadPercentage] = useState<number>();
  const [hasOpenedAttachmentsInfoModal, setHasOpenedAttachmentsInfoModal] = useState(false);
  const [attachmentError, setAttachmentError] = useState<ReactNode>();
  const [shouldShowJumpToBottom, setShouldShowJumpToBottom] = useState(false);

  const dispatch = useDispatch<ThunkDispatch<any, any, Action>>();

  const currentUser = useSelector((state: RootState) => state.profiles.currentUser);
  let proposalyId = useSelector((state: RootState) => state.messenger.messengerData.proposalyId);
  let proposalyType = useSelector(
    (state: RootState) => state.messenger.messengerData.proposalyType
  );
  // overwrite proposal ID if exists
  if (currentThread?.proposaly_id && currentThread.proposaly_type) {
    proposalyType = currentThread.proposaly_type;
    proposalyId = currentThread.proposaly_id;
  }

  // Used for draft message loading
  const messageRef = useRef(messageInput);
  const fetchingProposalIdRef = useRef(proposalyId);
  // Used for grabbing file from file input
  const fileInputRef = useRef<HTMLInputElement>(null);
  // Used for cancelling an in-progress upload
  const uploadAttachmentAbortControllerRef = useRef<AbortController>();
  // Used for scroll to just added attachment
  const attachmentsRef = useRef<HTMLDivElement | null>(null);
  // Used for auto-resizing text area
  const messageInputRef = useRef<HTMLTextAreaElement | null>(null);
  // Used for jump to bottom functionality
  const inputContainerRef = useRef<HTMLDivElement | null>(null);
  const inputBottomRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    // Reset state on thread change
    setMessageInput("");
    setAttachments([]);
    setShouldShowJumpToBottom(false);
    // Abort upload
    uploadAttachmentAbortControllerRef.current?.abort();
    // Only show attachments info modal once per conversation per session
    setHasOpenedAttachmentsInfoModal(false);

    ably.connect();
    const channel = ably.channels.get(`message_thread_${currentThread?.id}`);
    channel.subscribe(ABLY_CHANNELS.NEW_MESSAGE, function (message) {
      const messageData = JSON.parse(message.data);
      setMessages((messages) => [...messages, messageData]);
    });

    return () => {
      channel.unsubscribe();
    };
  }, [currentThread]);

  // Fetching drafts
  // TODO adapt for direct message
  const debouncedSetDraftMessage = debounce(setDraftMessage, 500);
  useEffect(() => {
    if (proposalyId) {
      (async () => {
        // Set the most recent currently-fetching proposal
        fetchingProposalIdRef.current = proposalyId;
        setIsFetchingDraft(true);

        const draftMessage = await getDraftMessage({ proposalyId, proposalyType });

        // This prevents applying the fetched draft in case a new draft started fetching in the meantime
        if (draftMessage && fetchingProposalIdRef.current === proposalyId) {
          // If the user started typing while the draft was fetching, this combines the
          // draft + input instead or replacing what they had typed with the fetched draft
          setMessageInput(draftMessage.content + messageRef.current);
          setAttachments(draftMessage.message_attachments);
        }

        setIsFetchingDraft(false);
      })();
    }
  }, [proposalyId]);

  // Setting drafts
  // TODO adapt for direct message
  useEffect(() => {
    // Reset message container size
    if (messageInput.length === 0 && messageInputRef.current) {
      messageInputRef.current.style.height = "unset";
    }

    if (proposalyId) {
      // Store the user's input so it can be added to the fetched draft after fetching is done
      messageRef.current = messageInput;

      // Don't set drafts while fetching, because they use the same record and can cause a race
      // condition.  Also prevents setting the draft as "" while the draft is fetching
      // Also don't set drafts while uploading an attachment (and do it afterwards)
      if (!(isFetchingDraft || isAttachmentUploading)) {
        debouncedSetDraftMessage({
          proposalyId,
          proposalyType,
          content: messageInput,
          attachmentIds: attachments
            .map((attachment) => attachment.id)
            .filter((id) => Boolean(id)) as unknown as number[],
        });
      }
    }
  }, [messageInput]);

  const openFileBrowser = () => {
    fileInputRef.current?.click();
  };

  const handleAttachmentInitiation = () => {
    // Open info modal if the first time, or go straight to file browser otherwise
    if (!hasOpenedAttachmentsInfoModal) {
      setHasOpenedAttachmentsInfoModal(true);
      setIsAttachmentsInfoModalOpen(true);
    } else {
      openFileBrowser();
    }
  };

  const handleAttachmentUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setIsAttachmentUploading(true);
    setIsAttachmentsInfoModalOpen(false);

    const files = event.target.files;

    if (!files || files.length === 0) {
      setIsAttachmentUploading(false);
      setAttachmentError("For some reason the upload didn't work. Please try again");
      return;
    }

    const file = files[0];

    if (file.size > MAX_FILE_SIZE_IN_BYTES) {
      setIsAttachmentUploading(false);
      setAttachmentError(
        <>
          This file is too large.
          <br />
          Please upload a file that is smaller than 5MB.
        </>
      );
      return;
    }

    if (
      attachments.some(
        (attachment) =>
          attachment.filename === file.name &&
          attachment.size_in_bytes === file.size &&
          attachment.mime_content_type === file.type
      )
    ) {
      setIsAttachmentUploading(false);
      setAttachmentError("You have already attached that file.");
      return;
    }

    // Display in progress attachment
    const oldAttachments = [...attachments];
    // flushSync is warned against by the React beta docs due to weird behavior
    // with flushing pending state. Here it makes sense, but be wary when
    // modifying this component logic.
    flushSync(() => {
      setAttachments([
        ...oldAttachments,
        {
          filename: file.name,
          size_in_bytes: file.size,
          mime_content_type: file.type as MessageAttachmentContentType,
        },
      ]);
    });

    const inputContainer = inputContainerRef.current;
    if (inputContainer && messageInputRef.current) {
      const hasVerticalScrollbar =
        inputContainer.scrollHeight > inputContainer.clientHeight &&
        messageInputRef.current.clientHeight > MESSAGE_INPUT_MIN_HEIGHT;

      if (hasVerticalScrollbar) {
        // Scroll to newly added attachment if scrollable
        attachmentsRef.current?.lastElementChild?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });

        // Show jump to bottom icon if scrollable
        setShouldShowJumpToBottom(true);
      }
    }

    // Upload attachment
    let attachment: MessageAttachmentAsJson | undefined;
    uploadAttachmentAbortControllerRef.current = new AbortController();
    try {
      attachment = await uploadAttachment(
        file,
        uploadAttachmentAbortControllerRef.current.signal,
        setUploadPercentage
      );
    } catch (_) {
      setAttachmentError("For some reason the upload didn't work, please try again");
      setAttachments(oldAttachments);
    }

    // Check if aborted
    if (attachment) {
      // Replace in progress attachment in state with uploaded attachment response
      const newAttachments = [...oldAttachments, attachment];
      setAttachments(newAttachments);

      // Update draft message after new attachment finished uploading
      debouncedSetDraftMessage({
        proposalyId,
        proposalyType,
        content: messageInput,
        attachmentIds: newAttachments
          .map((_attachment) => _attachment.id)
          .filter((id) => Boolean(id)) as unknown as number[],
      });
    }

    // eslint-disable-next-line require-atomic-updates
    event.target.value = "";
    setIsAttachmentUploading(false);
  };

  const handleAttachmentRemove = (attachmentId?: number) => {
    if (attachmentId) {
      const newAttachments = attachments.filter((attachment) => attachment.id !== attachmentId);
      setAttachments(newAttachments);

      // Remove attachment from draft message
      debouncedSetDraftMessage({
        proposalyId,
        proposalyType,
        content: messageInput,
        attachmentIds: newAttachments
          .map((_attachment) => _attachment.id)
          .filter((id) => Boolean(id)) as unknown as number[],
      });
    } else {
      // Abort upload
      uploadAttachmentAbortControllerRef.current?.abort();
      // Remove last (in progress) attachment
      setAttachments([...attachments.slice(0, attachments.length - 1)]);
    }
  };

  // Set message input value and resize textarea to fit text
  // Called on onChange AND onKeyDown to cover all input cases
  const handleInputResizeAndChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const element = e.target;
    if (!element) return;
    element.style.height = "inherit";
    element.style.height = `${element.scrollHeight}px`;

    setMessageInput(element.value);
  };

  // Scroll to bottom of message input and place cursor at end of input
  const jumpToBottom = () => {
    inputBottomRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
    });
    setShouldShowJumpToBottom(false);

    // Focus end of text
    const textarea = messageInputRef.current;
    if (!textarea) return;
    textarea.focus();
    textarea.setSelectionRange(messageInput.length, messageInput.length);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);

    const attachmentIds = attachments
      .map((attachment) => attachment.id)
      .filter(Boolean) as unknown as number[];

    if (currentThread?.id) {
      await addMessageToThread({
        messageThreadId: currentThread.id,
        content: messageInput,
        attachmentIds,
      });
    } else {
      const { message_thread } = await createMessageThread({
        proposalyType,
        proposalyId,
        userId: recipient.id,
        content: messageInput,
        attachmentIds,
      });
      dispatch(setMessengerThread(message_thread));
    }
    // TODO update the return value of addMessageToThread so that we can just add it to state
    // instead of re-fetching all messages

    setAttachments([]);
    setMessageInput("");
    setShouldShowJumpToBottom(false);
    setIsSubmitting(false);
  };

  const isExternalLead =
    currentUser.role === UserRole.SCIENTIST && currentUser.id !== currentThread?.scientist_user?.id;
  const isToExternalLead =
    currentUser.id === currentThread?.scientist_user?.id && recipient?.role === UserRole.SCIENTIST;

  let placeholderRecipientName = recipient?.name;
  if (currentThread?.request?.confidential && currentUser.id === currentThread?.scientist_user?.id)
    placeholderRecipientName = currentThread.company.private_alias;
  else if (isToExternalLead)
    placeholderRecipientName = `the ${currentThread?.company.company_name} team`;

  return (
    <>
      <FileInput
        type="file"
        accept="image/png, image/jpeg, application/pdf"
        ref={fileInputRef}
        onChange={handleAttachmentUpload}
      />
      <AttachmentsInfoModal
        isOpen={isAttachmentsInfoModalOpen}
        onClose={() => setIsAttachmentsInfoModalOpen(false)}
        openFileBrowser={openFileBrowser}
      />
      <ErrorModal
        isOpen={Boolean(attachmentError)}
        onClose={() => setAttachmentError(undefined)}
        message={attachmentError}
      />
      <Container isInbox={isInbox}>
        {currentThread?.request?.confidential &&
          (currentUser.role === USER_ROLES.sponsor || isExternalLead) && (
            <InfoBanner
              type="attention-orange"
              text={`You are messaging about an opportunity where ${
                isExternalLead ? "the" : "your"
              } company's name is anonymized. Do not reveal information about ${
                isExternalLead ? "the" : "your"
              } company.`}
              margin="-16px 0 16px"
              font={FONTS.REGULAR_3}
            />
          )}
        <InputContainer isInbox={isInbox} ref={inputContainerRef}>
          <div>
            {attachments.length > 0 && (
              <AttachmentsContainer ref={attachmentsRef}>
                {attachments.map((attachment) => (
                  <MessageAttachment
                    key={`attachment-${attachment.id ?? "in-prog"}`}
                    attachment={attachment}
                    isInbox={isInbox}
                    uploadPercentage={!attachment.id ? uploadPercentage : 100}
                    onRemove={() => handleAttachmentRemove(attachment.id)}
                    onClick={() => attachment.id && openGallery(attachments, attachment.id)}
                  />
                ))}
              </AttachmentsContainer>
            )}
            <TextInput
              data-testid="message-input"
              ref={messageInputRef}
              placeholder={
                isFetchingDraft && proposalyId
                  ? ""
                  : `Write a message to ${placeholderRecipientName}`
              }
              value={messageInput}
              isInbox={isInbox}
              onKeyDown={handleInputResizeAndChange}
              onChange={handleInputResizeAndChange}
              onFocus={() => setShouldShowJumpToBottom(false)}
            />
            <div ref={inputBottomRef} />
          </div>
        </InputContainer>
        <JumpToBottomContainer>
          <JumpToBottom isVisible={shouldShowJumpToBottom} onClick={jumpToBottom}>
            <Icon name="Chevron Down" size="lg" color={COLORS.WHITE} />
          </JumpToBottom>
        </JumpToBottomContainer>
        <BottomContainer isInbox={isInbox}>
          <WarningMessageContainer isInbox={isInbox}>
            <Icon name="Attention" color={COLORS.BLACK} />
            <WarningMessage isInbox={isInbox}>
              Do not include confidential information
            </WarningMessage>
          </WarningMessageContainer>
          <BottomButtonContainer>
            <IconButton
              iconName="Attachment"
              tooltipWidth="122px"
              tooltipText="Add attachments"
              onClick={handleAttachmentInitiation}
              disabled={isSubmitting || isAttachmentUploading}
              variant="ghost"
              data-testid="add-attachment-button"
            />
            <Tooltip
              isActive={!messageInput}
              tooltipWidth={isInbox ? "310px" : "120px"}
              position="top"
              content={
                isInbox
                  ? "Please include a message with your attachment(s)"
                  : "Please include a message"
              }
            >
              <Button
                type="button"
                data-testid="send-message-button"
                iconName="Send Message"
                iconPosition="right"
                onClick={handleSubmit}
                disabled={!messageInput || isSubmitting || isAttachmentUploading}
                size={isInbox ? "md" : "sm"}
              >
                Send
              </Button>
            </Tooltip>
          </BottomButtonContainer>
        </BottomContainer>
      </Container>
    </>
  );
};

export default MessageCompose;

const FileInput = styled.input`
  && {
    display: none;
  }
`;

const Container = styled.div`
  background-color: ${({ isInbox }) => (isInbox ? COLORS.NEUTRAL_100 : COLORS.WHITE)};
  padding: ${({ isInbox }) => (isInbox ? "24px" : "8px 10px 0px")};
  margin-top: 10px;
  border-radius: 8px;
`;

const BottomContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${({ isInbox }) => (isInbox ? "16px" : "8px")};
`;

const InputContainer = styled.div`
  background: ${({ isInbox }) => (isInbox ? COLORS.WHITE : COLORS.NEUTRAL_100)};
  max-height: 30vh;
  overflow-y: auto;
  // Use column-reverse to keep the auto-expanding text area scrolled down
  display: flex;
  flex-direction: column-reverse;
`;

const JumpToBottomContainer = styled.div`
  width: 100%;
  height: 0;
  position: relative;
`;

const JumpToBottom = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 15px;

  display: ${({ isVisible }) => (isVisible ? "flex" : "none")};
  align-items: center;
  justify-content: center;

  width: 30px;
  height: 30px;
  border-radius: 50%;

  background-color: ${COLORS.BLACK};
  box-shadow: ${STYLES.SHADOW_D};
  cursor: pointer;

  transition: opacity 0.3s ease-in;
  opacity: ${({ isVisible }) => (isVisible ? "0.5" : "0")};

  &:hover {
    opacity: 1;
  }
`;

const AttachmentsContainer = styled.div`
  padding: 16px 0 0 12px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const TextInput = styled.textarea`
  min-height: ${MESSAGE_INPUT_MIN_HEIGHT}px;
  width: 100%;
  padding: ${({ isInbox }) => (isInbox ? "14px 16px" : "8px 12px 0 12px")};
  resize: none;
  border-radius: 6px;
  border: none;
  ${FONTS.REGULAR_2};
  vertical-align: top;
  background: ${({ isInbox }) => !isInbox && COLORS.NEUTRAL_100};
  overflow: hidden;

  &::placeholder {
    ${FONTS.REGULAR_2};
    color: ${COLORS.NEUTRAL_500};
  }
`;

const WarningMessageContainer = styled.div`
  display: flex;
  align-items: center;
`;
const WarningMessage = styled.div`
  ${({ isInbox }) => (isInbox ? FONTS.MEDIUM_2 : FONTS.TAG_MEDIUM_2)};
`;
const BottomButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
