import { marketplacePath } from "@routes/utils";
import { payloadActionGenerator } from "@utils/redux";
import axios from "axios";
import api from "src/requests/request";

export const SHOW_ERROR_BOUNDARY = "SHOW_ERROR_BOUNDARY";
export const ADD_FILTER = "ADD_FILTER";
export const REMOVE_FILTER = "REMOVE_FILTER";
export const SEARCH_TERM = "SEARCH_TERM";
export const SET_FOLLOWED_REQUESTS_INFO = "SET_FOLLOWED_REQUESTS_INFO";
export const START_FOLLOW_UNFOLLOW_RFP_PROGRAM = "START_FOLLOW_UNFOLLOW_RFP_PROGRAM";
export const FINISH_FOLLOW_UNFOLLOW_RFP_PROGRAM = "FINISH_FOLLOW_UNFOLLOW_RFP_PROGRAM";
export const UPDATE_RESULT_LIST = "UPDATE_RESULT_LIST";
export const LOAD_CURRENT_RFP = "LOAD_CURRENT_RFP";
export const UPDATE_CURRENT_RFP = "UPDATE_CURRENT_RFP";
export const SET_CURRENT_COMPANY = "SET_CURRENT_COMPANY";
export const LOADING_CURRENT_COMPANY = "LOADING_CURRENT_COMPANY";
export const SET_COMPANIES = "SET_COMPANIES";
export const SET_FOUNDATIONS = "SET_FOUNDATIONS";
export const SET_GOVERNMENT_ORGANIZATIONS = "SET_GOVERNMENT_ORGANIZATIONS";
export const SET_LOAD_COMPANY_PARTNER = "SET_LOAD_COMPANY_PARTNER";
export const SET_LOAD_COMPANY_INTEREST = "SET_LOAD_COMPANY_INTEREST";
export const SET_COMPANY_INTEREST = "SET_COMPANY_INTEREST";
export const SET_COMPANY_INFO = "SET_COMPANY_INFO";
export const SET_LOAD_COMPANY_INFO = "SET_LOAD_COMPANY_INFO";
export const LOAD_DELETE_COMPANY_INTEREST_PARTNER = "LOAD_DELETE_COMPANY_INTEREST_PARTNER";
export const SET_INVITED_USERS = "SET_INVITED_USERS";
export const SET_LOAD_INVITED_USERS = "SET_LOAD_INVITED_USERS";
export const SET_UNIVERSITY_TEAM_MEMBERS = "SET_UNIVERSITY_TEAM_MEMBERS";
export const SET_COMPANY_LOGO = "SET_COMPANY_LOGO";
export const SET_COMPANY_VIDEO = "SET_COMPANY_VIDEO";
export const SET_LAST_VIEWED_RFP_IDENTIFIER = "SET_LAST_VIEWED_RFP_IDENTIFIER";

export const setShowErrorBoundary = payloadActionGenerator(SHOW_ERROR_BOUNDARY);
export const addFilter = payloadActionGenerator(ADD_FILTER);
export const removeFilter = payloadActionGenerator(REMOVE_FILTER);
export const searchTerm = payloadActionGenerator(SEARCH_TERM);
export const startFollowUnfollowRfpProgram = payloadActionGenerator(
  START_FOLLOW_UNFOLLOW_RFP_PROGRAM
);
export const finishFollowUnfollowRfpProgram = payloadActionGenerator(
  FINISH_FOLLOW_UNFOLLOW_RFP_PROGRAM
);
export const setFollowedRequestsInfo = payloadActionGenerator(SET_FOLLOWED_REQUESTS_INFO);
export const updateResultList = payloadActionGenerator(UPDATE_RESULT_LIST);
export const loadCurrentRFP = payloadActionGenerator(LOAD_CURRENT_RFP);
export const updateCurrentRFP = payloadActionGenerator(UPDATE_CURRENT_RFP);
export const setCurrentCompany = payloadActionGenerator(SET_CURRENT_COMPANY);
export const setCompanies = payloadActionGenerator(SET_COMPANIES);
export const setFoundations = payloadActionGenerator(SET_FOUNDATIONS);
export const setGovernmentOrganizations = payloadActionGenerator(SET_GOVERNMENT_ORGANIZATIONS);
export const setLoadingCurrentCompany = payloadActionGenerator(LOADING_CURRENT_COMPANY);
export const setLoadCompanyPartner = payloadActionGenerator(SET_LOAD_COMPANY_PARTNER);
export const setLoadCompanyInterest = payloadActionGenerator(SET_LOAD_COMPANY_INTEREST);
export const setCompanyInterest = payloadActionGenerator(SET_COMPANY_INTEREST);
export const setCompanyInfo = payloadActionGenerator(SET_COMPANY_INFO);
export const setLoadCompanyInfo = payloadActionGenerator(SET_LOAD_COMPANY_INFO);
export const loadDeleteCompanyInterestPartner = payloadActionGenerator(
  LOAD_DELETE_COMPANY_INTEREST_PARTNER
);
export const setInvitedUsers = payloadActionGenerator(SET_INVITED_USERS);
export const setLoadInvitedUsers = payloadActionGenerator(SET_LOAD_INVITED_USERS);
export const setUniversityTeamMembers = payloadActionGenerator(SET_UNIVERSITY_TEAM_MEMBERS);
export const setCompanyLogo = payloadActionGenerator(SET_COMPANY_LOGO);
export const setCompanyVideo = payloadActionGenerator(SET_COMPANY_VIDEO);
export const setLastViewedRFPIdentifier = payloadActionGenerator(SET_LAST_VIEWED_RFP_IDENTIFIER);

export const loadCompany = (identifier) => {
  return async (dispatch) => {
    dispatch(setLoadingCurrentCompany(true));

    try {
      const responseJson = await api.get(`/company_info/${identifier}`);

      if (responseJson.data) {
        dispatch(setCurrentCompany(responseJson.data.company));
        dispatch(setLoadingCurrentCompany(false));
        return responseJson.data;
      }

      window.location.href = marketplacePath();
    } catch (error) {
      if (error.response.status === 401) {
        const accessCode = prompt("Please enter the access code for this page");

        if (accessCode) {
          const responseJson = await api
            .get(`/company_info/${identifier}`, {
              params: {
                access_code: accessCode,
              },
            })
            .catch((secondError) => {
              if (secondError.response.status === 401) {
                alert("Incorrect password");
                window.location.href = marketplacePath();
              } else {
                throw secondError;
              }
            });

          if (responseJson?.data.company) {
            dispatch(setCurrentCompany(responseJson.data.company));
            dispatch(setLoadingCurrentCompany(false));
            return responseJson.data.company;
          }
        } else {
          window.location.href = marketplacePath();
        }
      } else {
        throw error;
      }
    }
  };
};

export const fetchCompanyNames = () => {
  return async (dispatch) => {
    const responseJson = await axios.get(`/companies?names=true`, {
      headers: {
        Accept: "application/json",
      },
    });

    dispatch(setCompanies(responseJson.data.companies));

    return responseJson.data.companies;
  };
};

export const fetchFoundations = () => {
  return async (dispatch) => {
    const responseJson = await axios.get(`/foundations`, {
      headers: {
        Accept: "application/json",
      },
    });

    dispatch(setFoundations(responseJson.data.foundations));

    return responseJson.data.foundations;
  };
};

export const fetchGovernmentOrganizations = () => {
  return async (dispatch) => {
    const responseJson = await axios.get(`/government_organizations`, {
      headers: {
        Accept: "application/json",
      },
    });

    dispatch(setGovernmentOrganizations(responseJson.data.government_organizations));

    return responseJson.data.government_organizations;
  };
};

export const fetchFollowedRequestsInfo = () => {
  return async (dispatch) => {
    try {
      const responseJson = await axios.get("/followed_requests_info", {
        headers: {
          Accept: "application/json",
        },
      });

      dispatch(setFollowedRequestsInfo(responseJson.data.followed_requests_info));
    } catch (err) {
      console.log(err);
    }
  };
};

export const followRfpProgram = (profile_info_id, research_application) => {
  return async (dispatch) => {
    dispatch(startFollowUnfollowRfpProgram());

    try {
      await api.post("/research_follows", {
        research_follows: {
          profile_info_id,
          research_application,
        },
      });

      dispatch(
        finishFollowUnfollowRfpProgram({
          rfpProgram: research_application,
          following: true,
        })
      );
    } catch (err) {
      dispatch(
        finishFollowUnfollowRfpProgram({
          rfpProgram: research_area,
          following: false,
        })
      );
    }
  };
};

export const unfollowRfpProgram = (profile_info_id, research_application) => {
  return async (dispatch) => {
    dispatch(startFollowUnfollowRfpProgram());
    const url = `/research_follows/${research_application}`;

    try {
      await api.delete(url);

      dispatch(
        finishFollowUnfollowRfpProgram({
          rfpProgram: research_application,
          following: false,
        })
      );
    } catch (err) {
      dispatch(
        finishFollowUnfollowRfpProgram({
          rfpProgram: research_application,
          following: true,
        })
      );
    }
  };
};

export const fetchInvitedUsers = (organizationId, role) => {
  return async (dispatch) => {
    dispatch(setLoadInvitedUsers(true));
    try {
      const responseJson = await api.get("/invited_users", {
        params: {
          organization_id: organizationId,
          role,
        },
      });
      dispatch(setInvitedUsers(responseJson.data));
    } catch (err) {
      dispatch(setLoadInvitedUsers(false));
    }
    dispatch(setLoadInvitedUsers(false));
  };
};
export const fetchUniversityMembers = (university_id) => {
  return async (dispatch) => {
    const responseJson = await axios.get(
      "/university_team",
      {
        params: { organization: university_id },
      },
      {
        headers: {
          Accept: "application/json",
        },
      }
    );
    dispatch(setUniversityTeamMembers(responseJson.data));
  };
};
