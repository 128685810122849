import { setPrevUrlCookie } from "@utils/authUtils";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { USER_ROLES } from "src/constants/users";
import PrivateRoute from "./PrivateRoute";

const PrivateSponsorRoute = ({ component, options, ...props }) => {
  const { currentUser } = props;
  if (!currentUser.id) {
    setPrevUrlCookie(window.location.href);
    window.location.href = "/login";
  }

  if (currentUser.role !== USER_ROLES.sponsor) {
    console.warn("User %o attempted to access sponsor-only route.", currentUser);
    return <Redirect to="/403" />;
  }

  return <PrivateRoute {...options} {...props} component={component} />;
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.profiles.currentUser,
  };
};

export default connect(mapStateToProps)(PrivateSponsorRoute);
